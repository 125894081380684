import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MetaData } from '../components/common/meta';
import BlogPostCard from '../components/blogCards/postCard';
import AuthorSvg from '../images/blog/writer.svg';
import BlogLayout from '../components/layout/blogLayout';
import Breadcrumb from '../components/breadcrumb';
import { saveURLParamToSession } from '../utils';

/**
 * Author page (blog/author/:slug)
 *
 * Loads all posts for the requested author
 *
 */
const Author = (props) => {
  const { data, location } = props;
  const author = data.ghostAuthor;
  const posts = data.allGhostPost.edges;

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <MetaData data={data} location={location} type="profile" />
      <BlogLayout location={location}>
        <Breadcrumb type="author" />
        <div className="tag-container author-container">
          <header className="author-header">
            <div className="author-meta container">
              <div className="author-image-container">
                <img
                  className="author-image"
                  src={author.profile_image || AuthorSvg}
                  alt={author.name}
                />
              </div>
              <h1 className="author-title">{author.name}</h1>
              {author.bio ? <p className="author-bio">{author.bio}</p> : null}
            </div>
          </header>
          <section className="post-feed container">
            <div className="row-new">
              {posts.map(({ node }) => (
                <div key={node.id} className="col-6">
                  <BlogPostCard {...node} />
                </div>
              ))}
            </div>
          </section>
        </div>
      </BlogLayout>
    </>
  );
};

Author.propTypes = {
  data: PropTypes.shape({
    ghostAuthor: PropTypes.shape({
      name: PropTypes.string.isRequired,
      cover_image: PropTypes.string,
      profile_image: PropTypes.string,
      website: PropTypes.string,
      bio: PropTypes.string,
      location: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string
    }),
    allGhostPost: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Author;

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          slug
          feature_image
          primary_author {
            profile_image
            name
            cover_image
            slug
          }
          excerpt
          primary_tag {
            name
            slug
          }
          published_at(formatString: "MMM DD, YYYY")
          title
          html
          reading_time
          codeinjection_styles
        }
      }
    }
    ghostAuthor(slug: { eq: $slug }) {
      bio
      cover_image
      facebook
      id
      location
      meta_description
      meta_title
      name
      postCount
      profile_image
      slug
      twitter
      url
      website
    }
  }
`;
